<template>
  <div v-loading="loaders.dynamicSectionCategories" class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>

    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">List of {{ dynamicContentSection.name }} Categories</h1>
      <div class="d-flex justify-content-end">
        <base-button class="btn-secondary-action title-btn-padding button-font" @click="toggleModal('Save')">Add New</base-button>
      </div>
    </div>
    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.dynamicSectionCategories"
        >Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.dynamicSectionCategories"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.dynamicSectionCategories"
        >Deactivate
        </base-button>
      </div>
      <el-table
        class="table-responsive table-flush"
        header-row-class-name="thead-light"
        width="100%"
        ref="multipleSelectionTable"
        :data="response.dynamicSectionCategories"
        @selection-change="dynamicSectionCategoriesMultiSelect"
      >
        <el-table-column prop="selected" type="selection" min-width="130px">
        </el-table-column>
        <el-table-column label="NAME" prop="name" min-width="130px">
          <template slot-scope="scope">
            <div class="ellipsis-overflow-text">{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" prop="status" min-width="130px">
          <template slot-scope="scope">
              <span :class="['status',getStatus(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS">
          <div slot-scope="{ $index, row }" class="d-flex">

            <img
              class="pointer"
              @click="toggleModal('Update' , row)"
              src="/img/icons/buttons/edit.svg"
              alt="Edit"
            />
            <img
              class="pointer ml-2"
              @click="handleDelete($index, row)"
              src="/img/icons/buttons/delete.svg"
              alt="Delete"
            />
          </div>
        </el-table-column>
      </el-table>
      <div class="col-12 d-flex justify-content-end flex-wrap">
        <custom-pagination
          class="mt-4 pagination-no-border float-right"
          v-model="request.pagination.page"
          :per-page="request.pagination.perPage"
          :total="request.pagination.total"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeDynamicSectionCategory"
        @onRemoveMultiple="deleteDynamicSectionCategories"
      />
      <el-dialog
        :title="modal.title"
        :visible.sync="modals.dynamicSectionCategoryModal"
        width="30%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <validation-observer v-slot="{handleSubmit}" ref="formDynamicSectionCategoryValidator">
          <form role="form" ref="formDynamicSectionCategory" :name="modal.formType"
                @submit.prevent="handleSubmit(onFormSubmit)">
            <div class="mt--4">
              <base-input
                class=""
                :rules="{required: true}"
                name="Category Name"
                label="Category Name*" v-model="dynamicSectionCategory.name"
                placeholder="Category Name">
              </base-input>

              <base-input label="Status">
                <el-select
                  class="w-100"
                  label="Status"
                  v-model="dynamicSectionCategory.status">
                  <el-option
                    v-for="option in dropdowns.status"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="text-right mt-4">
              <base-button class="cancel-btn" type="secondary" @click="handleClose">Cancel</base-button>
              <base-button class="btn-primary-action pl-5 pr-5 br-4" native-type="submit" type="success">{{ modal.buttonText }}
              </base-button>
            </div>
          </form>
        </validation-observer>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui"
import BackButton from "@/components/Router/BackButton"
import CustomPagination from "../../../../Components/Pagination/CustomPagination"
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

const RemoveModal = () => import("@/components/Modals/RemoveModal.vue")

export default {
  name: "DynamicContentSectionCategory",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal, BackButton, CustomPagination
  },
  data() {
    return {
      dynamicContentSection: {
        name: this.$route.params.sectionName ?? '',
        id: this.$route.params.sectionId ?? 0,
      },
      loaders: {
        dynamicSectionCategories: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      modals: {
        dynamicSectionCategoryModal: false,
      },
      modal: {
        title: 'Add Category',
        buttonText: 'Save',
        formType: 'Save',
      },
      dynamicSectionCategory: {
        id: '',
        name: '',
        status: 1
      },
      selectedDynamicSectionCategory: '',
      dropdowns: {
        status: [{
          value: 1,
          label: 'Active'
        },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      response: {
        dynamicSectionCategories: [],
      },
      request: {
        pagination: {
          page: 1,
          currentPage: 1,
          perPage: 20,
          total: 1,
        }
      },
    }
  },
  mounted() {
    //Condition to check if params are not passed from previous route then call the API
    if (this.dynamicContentSection.name == '') {
      this.getDynamicContentSection()
    }
    this.fetchDynamicSectionCategories()
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    /**
     * Get request dynamic content section detail
     */
    getDynamicContentSection() {
      let vm = this
      vm.loaders.dynamicSectionCategories = true
      const params = {id: vm.dynamicContentSection.id}

      vm.$store.dispatch('dynamicContentSectionModule/_getRequestDynamicContentSection', params)
        .then(response => {
          vm.dynamicContentSection = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name,
            message: message
          })
        })
        .finally(() => {
          vm.loaders.dynamicSectionCategories = false
        })
    },

    /**
     * Get all the dynamic section categories
     */
    fetchDynamicSectionCategories() {
      let vm = this
      vm.loaders.dynamicSectionCategories = true
      const params = {
        sectionId: vm.dynamicContentSection.id,
        page: vm.request.pagination.page
      }
      vm.$store.dispatch('dynamicContentSectionModule/_fetchAllDynamicSectionCategories', params)
        .then((response) => {
          vm.response.dynamicSectionCategories = response.data.data.data
          vm.request.pagination.page = response.data.data.current_page
          vm.request.pagination.perPage = response.data.data.per_page
          vm.request.pagination.total = response.data.data.total
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name,
            message: message
          })
        })
        .finally(() => vm.loaders.dynamicSectionCategories = false)
    },

    /**
     * Handle activate button event
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    /**
     * Handle deactivate button event
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Get status as a string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Handle event on multiple select
     * @param selectedRows
     */
    dynamicSectionCategoriesMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Handle update status event
     * @param ids
     * @param status
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.dynamicSectionCategories = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      }

      vm.$store.dispatch('dynamicContentSectionModule/_updateStatusDynamicSectionCategoryItem', payload)
        .then(response => {
          vm.removeId = []
          vm.fetchDynamicSectionCategories()
          vm.$notify.success({
            title: 'Success',
            message: vm.dynamicContentSection.name + ' category status updated successfully.'
          })
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Status Section Category Item',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.dynamicSectionCategories = false
        })
    },

    /**
     * Handle delete event
     * @param index
     * @param row
     */
    handleDelete(index, row) {
      this.promptRemove(row)
    },

    /**
     * Prompt the single remove modal
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this ' + this.dynamicContentSection.name + ' Category.\n' +
        'Deleting Category will remove all ' + this.dynamicContentSection.name + ' Info and Sub Categories associated with it.'
      this.removeId = row.id
    },

    /**
     * Prompt the Multi-remove modal
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove these ' + this.dynamicContentSection.name + ' Categories.\n' +
        'Deleting Category will remove all ' + this.dynamicContentSection.name + ' Info and Sub Categories associated with it.'
      this.removeId = this.extractSelectedIds
    },

    /**
     * Handle remove dynamic section category event
     * @param id
     */
    removeDynamicSectionCategory(id) {
      this.deleteRequest(id)
    },

    /**
     * handle delete dynamic section categories event
     */
    deleteDynamicSectionCategories() {
      this.deleteRequest(this.removeId)
    },

    /**
     * Delete Dynamic Section Category
     * @param data
     */
    deleteRequest(data) {
      let vm = this
      vm.loaders.dynamicSectionCategories = true
      vm.loaders.checkboxButton.Delete = true
      const payload = {ids: Array.isArray(data) ? data : [data]}

      vm.$store.dispatch('dynamicContentSectionModule/_deleteDynamicSectionCategoryItem', payload)
        .then((response) => {
          vm.removeId = []
          vm.fetchDynamicSectionCategories()
          vm.$store.commit("hideRemoveModal")
          vm.$notify.success({
            title: 'Success',
            message: vm.dynamicContentSection.name + ' category deleted successfully.'
          })
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Section Category Item',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.checkboxButton.Delete = false
          vm.loaders.dynamicSectionCategories = false
        })
    },

    /**
     * Toggle Modal event
     * @param modalType
     * @param row
     */
    toggleModal(modalType, row) {
      let vm = this
      if (modalType === 'Save') {
        vm.modal.title = 'Add ' + vm.dynamicContentSection.name + ' Category'
        vm.modal.buttonText = 'Save'
        vm.modal.formType = 'Save'
      } else if (modalType === 'Update') {
        vm.modal.title = 'Update ' + vm.dynamicContentSection.name + ' Category'
        vm.modal.buttonText = 'Update'
        vm.modal.formType = 'Update'
        vm.selectedDynamicSectionCategory = row.id
        vm.fetchDynamicSectionCategory()
      }
      vm.modals.dynamicSectionCategoryModal = !this.modals.dynamicSectionCategoryModal
    },

    /**
     * Get the requested dynamic section category
     */
    fetchDynamicSectionCategory() {
      let vm = this
      const payload = {
        id: vm.selectedDynamicSectionCategory,
        sectionId: vm.dynamicContentSection.id
      }
      vm.$store.dispatch('dynamicContentSectionModule/_showDynamicSectionCategoryItem', payload)
        .then((response) => {
          vm.dynamicSectionCategory.id = response.data.data.id
          vm.dynamicSectionCategory.name = response.data.data.name
          vm.dynamicSectionCategory.status = response.data.data.status
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name + ' Category',
            message: message
          })
        })
    },

    /**
     * For submit event
     * @returns {Promise<void>}
     */
    async onFormSubmit() {
      const isDynamicSectionCategoryFormValid = await this.$refs['formDynamicSectionCategoryValidator'].validate()

      if (isDynamicSectionCategoryFormValid) {
        if (this.$refs.formDynamicSectionCategory.name === 'Save') {
          this.storeDynamicSectionCategory()
        } else if (this.$refs.formDynamicSectionCategory.name === 'Update') {
          this.updateDynamicSectionCategory()
        } else {}
      } else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        })
      }
    },

    /**
     * Store Dynamic Section Category
     */
    storeDynamicSectionCategory() {
      let vm = this
      vm.loaders.dynamicSectionCategories = true
      vm.dynamicSectionCategory.sectionId = vm.dynamicContentSection.id

      vm.$store.dispatch('dynamicContentSectionModule/_storeDynamicSectionCategoryItem', vm.dynamicSectionCategory)
        .then(response => {
          vm.handleClose()
          this.fetchDynamicSectionCategories()
          this.modals.dynamicSectionCategoryModal = false
          vm.$notify.success({
            title: 'Success',
            message: vm.dynamicContentSection.name + ' category added successfully'
          })
        })
        .catch(error => {
          const message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name + ' Category',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          this.loaders.dynamicSectionCategories = false
        })
    },

    /**
     * Update Dynamic Section Category
     */
    updateDynamicSectionCategory() {
      let vm = this
      vm.loaders.dynamicSectionCategories = true
      vm.dynamicSectionCategory.sectionId = vm.dynamicContentSection.id

      vm.$store.dispatch('dynamicContentSectionModule/_updateDynamicSectionCategoryItem', vm.dynamicSectionCategory)
        .then(response => {
          vm.handleClose()
          this.fetchDynamicSectionCategories()
          this.modals.dynamicSectionCategoryModal = false
          vm.$notify.success({
            title: 'Success',
            message: vm.dynamicContentSection.name + ' category updated successfully'
          })
        })
        .catch(error => {
          const message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: vm.dynamicContentSection.name + ' Category',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          this.loaders.dynamicSectionCategories = false
        })
    },

    /**
     * Handle close event
     */
    handleClose() {
      this.clearDynamicSectionCategoryForm()
      this.$refs.formDynamicSectionCategoryValidator.reset()
      this.modals.dynamicSectionCategoryModal = false
    },

    /**
     * Reset form fields
     */
    clearDynamicSectionCategoryForm() {
      this.dynamicSectionCategory.id = ''
      this.dynamicSectionCategory.name = ''
      this.dynamicSectionCategory.status = 1
    },

    /**
     * Change page event
     * @param page
     */
    changePage(page) {
      if (page != this.request.pagination.page) {
        this.request.pagination.page = page
        this.fetchDynamicSectionCategories()
      }
    },
  },
  computed: {
    /**
     * Extract the selected ids
     * @returns {*[]}
     */
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  }
}
</script>

<style scoped>
.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}
</style>
